import {AlertInterface} from "../../../infrastructure/providers/AlertsProvider.tsx";
import {memo} from "react";
import {SvgIconType} from "../../../domain/core/models/SvgIconType.ts";
import {Classes} from "../../../infrastructure/services/utils/Classes.ts";
import classes from "./alert.module.scss";
import {SvgIcon} from "../icons/SvgIcon.tsx";

interface AlertComponentInterface extends AlertInterface {
    onClose?: ((id: number) => void)
}

export const Alert = memo((
    { id, type, message, onClose, closable }: AlertComponentInterface
) => {
    const handleAlertTypeIcon = (type: string) => {
        switch (type) {
            default:
                return SvgIconType.Icon
        }
    }

    return (
        <div className={Classes(classes.alert, classes[type])}>
            <div className={classes.content}>
                <div>
                    {type && (
                        <SvgIcon type={handleAlertTypeIcon(type)} className={classes.icon} />
                    )}
                    <p className={classes.alertContent}>
                        {message}
                    </p>
                </div>
                {closable && (
                    <div className={classes.action}>
                        <SvgIcon
                            type={'remove'}
                            className={classes.icon}
                            onClick={() => onClose ? onClose(id ?? 0) : null}
                        />
                    </div>
                )}
            </div>
        </div>
    )
})
