import {createReducer} from "@reduxjs/toolkit";
import {
    addConnectionAction,
    clearBankProviderWidgetAction, removeConnectionByExternalIdAction,
    updateBankProviderWidgetAction
} from "../actions/bankProviderWidgetActions.ts";

export interface BankProviderWidgetReducerState {
    url: string|null,
    provider: string|null,
    connectedConnections: string[]
}

export const initState: BankProviderWidgetReducerState = {
    url: null,
    provider: null,
    connectedConnections: []
};

const bankProviderWidgetReducer = createReducer(initState, (builder) => {
    builder.addCase(updateBankProviderWidgetAction, (state, action) => {
        return {
            ...state,
            ...action?.payload
        };
    })

    builder.addCase(clearBankProviderWidgetAction, () => {
        return initState;
    })

    builder.addCase(addConnectionAction, (state, action) => {
        if (state) {
            const connections = [...state.connectedConnections];

            if (!connections.includes(action.payload)) {
                connections.push(action.payload);
            }

            return {
                ...state,
                connectedConnections: connections
            }
        }
    })
    builder.addCase(removeConnectionByExternalIdAction, (state, action) => {
        const connections = state.connectedConnections.filter(connectedConnection => connectedConnection !== action.payload);

        return {
            ...state,
            connectedConnections: connections
        }
    })
})

export default bankProviderWidgetReducer;
