import Keycloak, {KeycloakConfig} from "keycloak-js";

class KeycloakService {
    private static instance: KeycloakService;
    private keycloak: Keycloak|null;

    private constructor() {
        this.keycloak = null;
    }

    public static getInstance = (): KeycloakService => {
        if (!this.instance) {
            this.instance = new KeycloakService();
        }

        return this.instance;
    }

    public initKeycloak = (config: KeycloakConfig): Keycloak => {
        this.keycloak = new Keycloak(config);
        return this.keycloak;
    }

    public getKeycloak = (): Keycloak|null => {
        return this.keycloak;
    }
}

export default KeycloakService.getInstance();

