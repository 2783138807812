import {QueryProps} from "../../../domain/core/models/QueryProps.ts";
import {useMutation, useQuery} from "@tanstack/react-query";
import UsersRepository from "../../repositories/UsersRepository.ts";
import {UsersConfirmAggregatePayload} from "../../../domain/queries/UsersConfirmAggregatePayload.ts";

export const useQueryGetCurrentUser = (props: QueryProps = undefined) => {
    return useQuery({
        ...props,
        queryKey: props?.queryKey ?? ['USE_QUERY_GET_CURRENT_USER'],
        queryFn: () => UsersRepository.get()
    });
}

export const useMutationUserAuthSilently = () => {
    return useMutation({
        mutationKey: ['USER_AUTH_SILENTLY'],
        mutationFn: (payload: object) => UsersRepository.authSilently(payload)
    });
}

export const useMutationUserConfirmAggregation = () => {
    return useMutation({
        mutationKey: ['USER_CONFIRM_AGGREGATION'],
        mutationFn: (payload: UsersConfirmAggregatePayload) => UsersRepository.confirmAggregate(payload)
    })
}
