import axios, {AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import BaseUrlInterceptor from "./interceptors/request/BaseUrlInterceptor.ts";
import BearerTokenInterceptor from "./interceptors/request/BearerTokenInterceptor.ts";
import LogResponseInterceptor from "./interceptors/response/LogResponseInterceptor.ts";
import ErrorHandlerResponseInterceptor from "./interceptors/response/ErrorHandlerResponseInterceptor.ts";
import {CURRENT_ENV, ENV} from "../../domain/core/constants";
import PartnerService from "../services/core/PartnerService";

class AxiosClient {
    private static instance: AxiosClient;
    private axiosInstance: AxiosInstance;

    private constructor() {
        this.axiosInstance = axios.create();

        this.useRequestInterceptors();
        this.useResponseInterceptors();
    }

    static getInstance(): AxiosClient {
        if (!this.instance) {
            this.instance = new AxiosClient();
        }

        return this.instance;
    }

    getAxios() {
        return this.axiosInstance;
    }

    private useRequestInterceptors = (): void => {
        this.axiosInstance.interceptors.request.use(async (config:InternalAxiosRequestConfig) => {
            config = BaseUrlInterceptor(config);
            config = BearerTokenInterceptor(config);
            const {iam} = await PartnerService.getSpecifics();
            config.headers.set('x-oidc-client-id', iam.clientId);

            return config;
        });
    }

    private useResponseInterceptors = (): void => {
        this.axiosInstance.interceptors.response.use((response:AxiosResponse) => {
            if (CURRENT_ENV !== ENV.Prod) {
                response = LogResponseInterceptor(response);
            }

            return response;
        }, (error: AxiosError) => {
            error = ErrorHandlerResponseInterceptor(error);

            return error;
        });
    }
}

export default AxiosClient.getInstance().getAxios();
