import {CURRENT_ENV, ENV, MEDIA_URL} from "../../../domain/core/constants";
import PartnerService from "./PartnerService";

export class IconService {
    public static init = async () => {
        const svgContent = await this.loadFromCDN();

        if (svgContent) {
            this.addToDOM(svgContent);
        }
    }

    private static loadFromCDN = async () => {
        try {
            const partner = PartnerService.getPartnerFromUrl();

            const url = `${MEDIA_URL}/data/partners/${partner}/icons/icons.svg`;
            const response = await fetch(url);

            if (response.ok) {
                return response?.text();
            } else {
                return undefined;
            }
        } catch (e) {
            if (CURRENT_ENV !== ENV.Prod) {
                console.groupCollapsed("Error while fetching SVG icons from CDN");
                console.log(e);
                console.groupEnd();
            }

            return undefined;
        }
    }

    private static addToDOM = (svgContent: string) => {
        const div = document.getElementById("#svg-sprites");

        if (div) {
            div.innerHTML = svgContent;
        } else {
            const div = document.createElement("div");
            div.id = "svg-sprites";
            div.innerHTML = svgContent;
            document.body.appendChild(div);
        }
    }
}
