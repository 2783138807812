import {createReducer} from "@reduxjs/toolkit";
import {clearCurrentUserAction, updateCurrentUserAction} from "../actions/currentUserActions";
import {User} from "../../../domain/users/models/input/User.ts";

export type CurrentUserReducerState = User;

export const initState: CurrentUserReducerState|null = null;

const currentUserReducer = createReducer(
    initState,
    (builder) => {
        builder
            .addCase(clearCurrentUserAction, () => {
                return null;
            });

        builder
            .addCase(updateCurrentUserAction, (state, action) => {
                return {
                    ...(state ?? {}),
                    ...action.payload
                } as unknown as void;
            });
    }
)

export default currentUserReducer;
