import AxiosClient from "../api/AxiosClient.ts";
import {URL_SETTINGS} from "../api/constants.ts";
import {Settings} from "../../domain/settings/models/input/Settings.ts";

export default class SettingsRepository {
    public static get = async (): Promise<Settings> => {
        const result = await AxiosClient.get(URL_SETTINGS());

        return result.data as Settings;
    }
}
