import {configureStore, Reducer} from "@reduxjs/toolkit";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from "./reducers";
import {CURRENT_ENV, ENV} from "../../domain/core/constants";
import {PersistConfig} from "redux-persist/es/types";
import {reduxPersistExpireReducer} from "./transformers/redux-persist-expire.ts";

import {initState as bankProviderWidgetReducerInitState} from "./reducers/bankProviderWidgetReducer.ts";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
const persistConfig: PersistConfig<Partial<{}>> = {
    key: 'root',
    version: 1,
    storage,
    blacklist: ["currentUser", "appReturnUrl"],
    transforms: [
        reduxPersistExpireReducer('bankProviderWidget', {
            expireSeconds: 3600,
            expiredState: bankProviderWidgetReducerInitState,
            autoExpire: true
        }),
    ]
};

const persistedReducer = persistReducer(persistConfig, reducers as Reducer)

const store = configureStore({
    reducer: persistedReducer,
    devTools: {
        trace: CURRENT_ENV === ENV.Dev
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
})

export const persistor = persistStore(store);
export default store;
