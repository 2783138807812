import {InternalAxiosRequestConfig} from "axios";
import KeycloakService from "../../../services/core/KeycloakService.ts";

const interceptor = (config: InternalAxiosRequestConfig) => {
    const keycloak = KeycloakService.getKeycloak();

    if (!config || !config?.headers || !keycloak?.authenticated || !keycloak?.token)
        return config;

    config.headers.Authorization = `Bearer ${keycloak?.token}`;

    return config;
}

export default interceptor;
