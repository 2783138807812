import classes from "./spinner.module.scss";
import {memo} from "react";
import {Classes} from "../../../infrastructure/services/utils/Classes.ts";
import {ValueOf} from "../../../domain/core/models/ValueOf.ts";

export enum SpinnerSize {
    Default = "default",
    Small = "small",
    Mini = "mini"
}

export enum SpinnerColor {
    Default = "default",
    Primary = "primary"
}

interface SpinnerProps {
    size?: ValueOf<SpinnerSize>
    color?: ValueOf<SpinnerColor>
    className?: string
}

export const Spinner = memo((
    { size = SpinnerSize.Default, color = SpinnerColor.Default, className }: SpinnerProps
) => {
    return (<div className={Classes(classes.loader, classes[size], classes[color], className)}></div>)
})
