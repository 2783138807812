import {createAction} from "@reduxjs/toolkit";
import {BankProviderWidgetReducerState} from "../reducers/bankProviderWidgetReducer.ts";

export const BANK_PROVIDER_WIDGET_UPDATE_ACTION_TYPE = 'BANK_PROVIDER_WIDGET_URL/UPDATE';
export const BANK_PROVIDER_WIDGET_CLEAR_ACTION_TYPE = 'BANK_PROVIDER_WIDGET_URL/DELETE';
export const ADD_CONNECTION_ACTION_TYPE = 'BANK_PROVIDER_WIDGET_URL/ADD_CONNECTION';
export const REMOVE_CONNECTION_BY_EXTERNAL_ID_ACTION_TYPE = 'BANK_PROVIDER_WIDGET_URL/REMOVE_CONNECTION_BY_ID';

export const updateBankProviderWidgetAction = createAction<BankProviderWidgetReducerState>(BANK_PROVIDER_WIDGET_UPDATE_ACTION_TYPE);
export const clearBankProviderWidgetAction = createAction(BANK_PROVIDER_WIDGET_CLEAR_ACTION_TYPE);
export const addConnectionAction = createAction<string>(ADD_CONNECTION_ACTION_TYPE);
export const removeConnectionByExternalIdAction = createAction<string>(REMOVE_CONNECTION_BY_EXTERNAL_ID_ACTION_TYPE)
