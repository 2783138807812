export const Classes = (...classesArray: (string|null|undefined)[]) => {
    let str = "";

    classesArray.forEach((c:string|null|undefined, i:number) => {
        if (i && c && str !== "") str += " ";
        if (c) str += c;
    });

    return str;
}
