import {useQuery} from "@tanstack/react-query";
import SettingsRepository from "../../repositories/SettingsRepository.ts";
import {QueryProps} from "../../../domain/core/models/QueryProps.ts";

export const useQueryGetSettings = (props: QueryProps = undefined) => {
    return useQuery({
        ...props,
        queryKey: props?.queryKey ?? ['USE_QUERY_GET_SETTINGS'],
        queryFn: () => SettingsRepository.get()
    });
}
