import { QueryClient } from '@tanstack/react-query';

class QueryClientSingleton {
    private static instance: QueryClientSingleton;
    private queryClient: QueryClient;

    private constructor() {
        this.queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: false
                }
            }
        });
    }

    public static getInstance(): QueryClientSingleton {
        if (!QueryClientSingleton.instance) {
            QueryClientSingleton.instance = new QueryClientSingleton();
        }

        return QueryClientSingleton.instance;
    }

    public getQueryClient(): QueryClient {
        return this.queryClient;
    }
}

export default QueryClientSingleton.getInstance().getQueryClient();
