import React, {createContext, PropsWithChildren, useCallback, useContext, useMemo, useState} from "react";
import {Alert} from "../../ui/components/layout/Alert";
import {ValueOf} from "../../domain/core/models/ValueOf";

export enum AlertTypes {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error'
}

export interface AlertInterface {
    id?: number,
    type: ValueOf<AlertTypes>,
    message: string,
    closable?: boolean,
    duration?: number
}

interface AlertsContextInterface {
    alerts: AlertInterface[],
    create: ((alert: AlertInterface) => void),
    remove: (id: number) => void
}

const AlertsContext = createContext<AlertsContextInterface>({
    alerts: [],
    create: () => null,
    remove: () => null
})

export const AlertsProvider = ({children}: PropsWithChildren): React.JSX.Element => {
    const [alerts, setAlerts] = useState<AlertInterface[]>([]);

    const remove = useCallback((id: number) => {
        setAlerts(prev => prev.filter(a => a.id !== id))
    }, [setAlerts])

    const create = useCallback((alert: AlertInterface) => {
        if (!alert.id) {
            alert.id = Math.floor(Math.random() * Date.now());
        }

        setAlerts(prev => [
            ...prev,
            alert
        ]);

        if (alert.duration && alert.duration > 0) {
            setTimeout(() => {
                remove(alert.id!);
            }, alert.duration)
        }
    }, [setAlerts, remove]);

    const contextValues: AlertsContextInterface = useMemo(() => ({
        alerts: alerts,
        create: create,
        remove: remove
    }), [alerts, create, remove]);

    return (
        <AlertsContext.Provider value={contextValues}>
            <div className={"alerts-container"}>
                {alerts.map(a => (
                    <Alert
                        key={a.id}
                        id={a.id}
                        type={a.type}
                        message={a.message}
                        closable={a.closable}
                        onClose={remove}
                    />
                ))}
            </div>

            {children}
        </AlertsContext.Provider>
    )
}

export const useAlerts = () => {
    return useContext(AlertsContext);
}
