export const PATH_MAINTENANCE = () => `/maintenance`;
export const PATH_HOME = () => `/`;
export const PATH_PROCESS_CONFIRMATION = () => `/confirmation`;

/**
 * OpenBanking
 */
export const PATH_REASSURANCE = () => `/reassurance`;
export const PATH_CONNECTIONS_MANAGE = () => `/manage-connection`;
export const PATH_CONNECTIONS_IN_PROGRESS = () => `/connections-progress`;
export const PATH_CONNECTIONS_SUCCESS = () => `/connections-success`;
export const PATH_CONNECTIONS_ERROR = () => `/connections-error`;
export const PATH_ACCOUNT_SELECTION = () => `/accounts-selection`;
export const PATH_PROCESS_CANCELED = () => `/process-canceled`;
