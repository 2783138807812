import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import 'dayjs/locale/fr';
import MultipleHttpBackends from "./translations/MultipleHttpBackends";

export class TranslationService {
    public static init = async (customTranslationsName?: string) => {
        await this.initWordingService(customTranslationsName);
        this.initDateService();
    }

    private static initWordingService = async (customTranslationsName?: string): Promise<void> => {
        const backends = [
            '/assets/locales/{{lng}}/translation.json'
        ];

        if (customTranslationsName) {
            backends.push(`/assets/locales/{{lng}}/partners/${customTranslationsName}`);
        }

        await i18n
            .use(new MultipleHttpBackends())
            .use(initReactI18next)
            .init({
                // Default config
                lng: (navigator.language || "fr").split("-")[0],
                fallbackLng: "fr",
                supportedLngs: ['fr'],

                interpolation: {
                    escapeValue: false
                },

                // Load translation files
                backend: {
                    loadPaths: backends
                }
            });
    }

    private static initDateService = () => {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        dayjs.tz.setDefault('Europe/Paris');
        dayjs.locale('fr');
    }
}

export default i18n;
