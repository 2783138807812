import {createContext} from "react";
import {PartnerSpecifics} from "../../../domain/core/models/PartnerSpecifics.ts";
import {Settings} from "../../../domain/settings/models/input/Settings.ts";

export interface ConfigContextInterface {
    loading: boolean,
    settings: Settings|null,
    specifics: PartnerSpecifics|null

}

export const ConfigContext = createContext<ConfigContextInterface>({
    loading: true,
    settings: null,
    specifics: null
});
