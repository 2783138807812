import {InternalAxiosRequestConfig} from "axios";
import PartnerService from "../../../services/core/PartnerService";

const interceptor = (request: InternalAxiosRequestConfig) => {
    request.baseURL = PartnerService.getPartnerAPIFromUrl();

    return request;
}

export default interceptor;
