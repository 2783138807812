/**
 * ENV
 */

export enum ENV {
    Prod = 'prod',
    Preprod = 'preprod',
    Dev = 'dev',
    Staging = 'staging'
}

export const CURRENT_ENV = import.meta.env.VITE_ENV as ENV ?? ENV.Dev;

/**
 * Dev
 */

export const PARTNER_SLUG = import.meta.env.VITE_PARTNER_SLUG as string|undefined;

/**
 * Tracing
 */

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;

/**
 * URLs
 */

export const SMARTPUSH_API_BASE_URL = import.meta.env.VITE_SMARTPUSH_API_BASE_URL as string;
export const MEDIA_URL = import.meta.env.VITE_MEDIA_CDN as string;

/**
 * BMW
 */

export const BMW_ANALYST_ROLES = ['STUDY_OPERATOR', 'LEVEL_2', 'ANALYST'];
