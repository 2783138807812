import {memo, SVGProps} from "react";
import {Classes} from "../../../infrastructure/services/utils/Classes.ts";
import classes from "./svg-icon.module.scss";
import {ValueOf} from "../../../domain/core/models/ValueOf.ts";
import {SvgIconType} from "../../../domain/core/models/SvgIconType.ts";


export interface SVGIconPropsInterface extends SVGProps<SVGSVGElement> {
    type: ValueOf<SvgIconType>
}

export const SvgIcon = memo((
    { type, ...props }: SVGIconPropsInterface
) => {
    return (
        <svg
            {...props}
            className={Classes(classes.icon, props.className)}
        >
            <use xlinkHref={"#" + type}></use>
        </svg>
    );
})
