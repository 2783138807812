import {PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {useQueryGetSettings} from "../hooks/api/useSettings.ts";
import PartnerService from "../services/core/PartnerService.ts";
import {PartnerSpecifics} from "../../domain/core/models/PartnerSpecifics.ts";
import {ConfigContext, ConfigContextInterface} from "./context/ConfigContext.ts";
import {TranslationService} from "../services/core/TranslationService.ts";
import {ThemeService} from "../services/core/ThemeService.ts";

export const ConfigProvider = ({ children }: PropsWithChildren) => {
    const [specifics, setSpecifics] = useState<PartnerSpecifics|undefined>();

    const {
        data: settings
    } = useQueryGetSettings();

    useEffect(() => {
        const asyncSetSpecifics = async () => {
            const specificsData: PartnerSpecifics = await PartnerService.getSpecifics();
            await TranslationService.init(specificsData.additionalResources?.translation);

            setSpecifics(specificsData);
        }

        void asyncSetSpecifics();
    }, []);

    useEffect(() => {
        if (settings) {
            ThemeService.setSettingsSassColors(settings);
        }
    }, [settings])

    const isAppLoading = useMemo(() => {
        return !settings || !specifics;
    }, [settings, specifics]);

    const contextValues: ConfigContextInterface = useMemo(() => {
        return {
            loading: isAppLoading,
            settings: settings ?? null,
            specifics: specifics ?? null
        }
    }, [isAppLoading, specifics, settings]);

    return (
        <ConfigContext.Provider value={contextValues}>
            {children}
        </ConfigContext.Provider>
    )
}

export const useConfig = () => {
    return useContext(ConfigContext);
};
