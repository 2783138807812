import { createRoot } from 'react-dom/client'
import SentryService from "./infrastructure/services/core/SentryService.ts";
import {QueryClientProvider} from "@tanstack/react-query";
import ReactQueryClient from "./infrastructure/api/ReactQueryClient.ts";
import {CURRENT_ENV, ENV} from "./domain/core/constants.ts";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import App from './App.tsx'
import {ConfigProvider} from "./infrastructure/providers/ConfigProvider.tsx";
import {AuthProvider} from "./infrastructure/providers/AuthProvider.tsx";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import store, {persistor} from "./infrastructure/redux/store.ts";
import "./main.scss";
import {IconService} from "./infrastructure/services/core/IconService.ts";
import {configuration as OpenDataLibConfig} from "@smartpush/open-data-librairies";
import AxiosClient from "./infrastructure/api/AxiosClient.ts";
import {AlertsProvider} from "./infrastructure/providers/AlertsProvider.tsx";

void IconService.init();

OpenDataLibConfig.configure({
    axiosClient: AxiosClient,
    queryClient: ReactQueryClient
});

createRoot(document.getElementById('root')!).render(
    <SentryService.ErrorBoundary>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={ReactQueryClient}>
                    {[ENV.Dev, ENV.Staging].includes(CURRENT_ENV) && (
                        <ReactQueryDevtools initialIsOpen={false} />
                    )}

                    <ConfigProvider>
                        <AuthProvider>
                            <AlertsProvider>
                                <App />
                            </AlertsProvider>
                        </AuthProvider>
                    </ConfigProvider>
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    </SentryService.ErrorBoundary>
)
