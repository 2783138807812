import {useConfig} from "./infrastructure/providers/ConfigProvider.tsx";
import {useMemo} from "react";
import {useAuth} from "./infrastructure/providers/AuthProvider.tsx";
import {PATH_MAINTENANCE} from "./ui/routing/constants.ts";
import {RouterProvider} from "react-router-dom";
import {Router} from "@remix-run/router";
import {AppLoader} from "./ui/components/loading/AppLoader.tsx";

function App() {
    const config = useConfig();
    const auth = useAuth();

    const ready = useMemo(() => {
        return (!auth?.loading && !config?.loading) || window.location.pathname === PATH_MAINTENANCE();
    }, [auth?.loading, config?.loading]);

    if (!ready || !config.specifics?.router) {
        return (<AppLoader />)
    }

    return (
        <RouterProvider router={config.specifics?.router({ auth, config }) as Router} />
    )
}

export default App
