import {PartnerSpecifics, PartnerSpecificsModule} from "../../../domain/core/models/PartnerSpecifics.ts";
import {CURRENT_ENV, ENV, PARTNER_SLUG, SMARTPUSH_API_BASE_URL} from "../../../domain/core/constants.ts";

class PartnerService {
    private static instance: PartnerService;
    private specifics: PartnerSpecifics|undefined;
    private specificsPromise: Promise<PartnerSpecificsModule>|undefined;

    public static getInstance = (): PartnerService => {
        if (!this.instance) {
            this.instance = new PartnerService();
        }

        return this.instance;
    }

    public getSpecifics = async (): Promise<PartnerSpecifics> => {
        // These conditions avoid a double loading on app start
        if (!this.specifics && !this.specificsPromise) {
            const partnerSlug = this.getPartnerFromUrl();
            this.specificsPromise = import(`../../../domain/core/specifics/${partnerSlug}.tsx`);

            this.specifics = (await this.specificsPromise).default;
            this.assignPartnerSpecificsValues();
        } else if (!this.specifics && this.specificsPromise) {
            this.specifics = (await this.specificsPromise).default;
            this.assignPartnerSpecificsValues();
        }

        return this.specifics!;
    }

    public getPartnerAPIFromUrl = (): string => {
        const partnerSlug = this.getPartnerFromUrl();
        return (SMARTPUSH_API_BASE_URL ?? '').replace(':partner', partnerSlug);
    }

    public getPartnerFromUrl = (): string => {
        if (CURRENT_ENV === ENV.Dev) {
            return PARTNER_SLUG ?? '';
        }

        const domain = window.location.hostname;
        const domainSplit = domain.split('.');

        if (domainSplit[0].includes('-')) {
            return domainSplit[0].split('-')[0];
        } else {
            return domainSplit[0];
        }
    }

    private assignPartnerSpecificsValues = () => {
        if (!this.specifics) {
            return;
        }

        const title = `<title>${this.specifics?.title}</title>`;
        const fonts: string[] = [];

        for (const font of this.specifics?.additionalResources?.fonts ?? []) {
            fonts.push(`<link href='${font}' rel="stylesheet" />`);
        }

        const head =  document.getElementsByTagName('head')[0];
        head.innerHTML = head.innerHTML + title + fonts.join('');
    }
}

export default PartnerService.getInstance();
