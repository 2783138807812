import {combineReducers} from "@reduxjs/toolkit";
import currentUserReducer from "./reducers/currentUserReducer.ts";
import bankProviderWidgetReducer from "./reducers/bankProviderWidgetReducer.ts";
import appReturnUrlReducer from "./reducers/appReturnUrlReducer.ts";

export default combineReducers({
    bankProviderWidget: bankProviderWidgetReducer,
    currentUser: currentUserReducer,
    appReturnUrl: appReturnUrlReducer
})
