import classes from "./app-loader.module.scss";
import {Spinner} from "./Spinner.tsx";
import {useAuth} from "../../../infrastructure/providers/AuthProvider.tsx";
import {useConfig} from "../../../infrastructure/providers/ConfigProvider.tsx";
import {useTranslation} from "react-i18next";
import {memo} from "react";

export const AppLoader = memo(() => {
    const auth = useAuth();
    const config = useConfig();
    const [t, i18n] = useTranslation();

    return (
        <div className={classes.appLoading}>
            <Spinner />

            {i18n.isInitialized && (
                <h2>
                    {config.loading && (
                        t('AppLoader.initializing')
                    )}

                    {!config.loading && auth.loading && (
                        t('AppLoader.loading')
                    )}
                </h2>
            )}
        </div>
    )
});
