import {AxiosError} from "axios";
import {PATH_MAINTENANCE} from "../../../../ui/routing/constants";

const interceptor = (error: AxiosError): AxiosError => {
    // Unhandled API errors
    if (
        (typeof error.response === "undefined" || error.response?.status === 500)
        && window.location.pathname !== PATH_MAINTENANCE()
    ) {
        window.location.pathname = PATH_MAINTENANCE();
    }
    throw error;
}

export default interceptor;
