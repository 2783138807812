import {AxiosResponse} from "axios";

const interceptor = (response: AxiosResponse) => {
    console.groupCollapsed("Query " + response?.config?.url);

    console.groupCollapsed("Request")
    console.log("URI", (response?.config?.baseURL ?? '') + response?.config?.url);
    console.log("Base URL", response?.config?.baseURL);
    console.log("URL", response?.config?.url);
    console.log("Data", response?.config?.data);
    console.log("Headers", response?.config?.headers);
    console.groupEnd()

    console.groupCollapsed("Response");
    console.log("Status", response?.status);
    console.log("Status text", response?.statusText);
    console.log('Data', response?.data);
    console.log('Headers', response?.headers);
    console.groupEnd();

    console.groupEnd();

    return response;
}

export default interceptor;
