import {ModuleType, Services} from "i18next";
import axios from "axios";

export type TranslationContent = Record<string, string|Record<string, string>>;

class MultipleHttpBackends {
    public type: ModuleType = 'backend';
    private loadPaths: string[] = [];


    public init = (
        // @ts-expect-error unused params
        services: Services,
        backendOptions: Record<string, string>
    ) => {
        if (backendOptions?.loadPaths && Array.isArray(backendOptions?.loadPaths)) {
            this.loadPaths = backendOptions.loadPaths;
        }
    }

    public read = async (
        language: string,
        // @ts-expect-error unused params
        namespace:string,
        callback: (err: unknown, data: TranslationContent|null) => void
    ) => {
        try {
            const translations = await this.getTranslationsFromUrls(
                this.loadPaths,
                language
            );

            callback(null, this.concatTranslations(translations));
        } catch (e) {
            callback(e, null);
        }
    }

    private getTranslationsFromUrls = async (arr: string[], language: string) => {
        const results = await axios.all(
            arr.map((endpoint) => {
                return axios.get(endpoint.replace('{{lng}}', language))
            })
        )

        return results.map((result) => {
            return result?.data as TranslationContent;
        })
    }

    private concatTranslations = (arr: TranslationContent[]): TranslationContent => {
        let translation = {};

        for (const content of arr) {
            translation = {
                ...translation,
                ...content
            }
        }

        return translation;
    }
}

export default MultipleHttpBackends;
