import {createReducer} from "@reduxjs/toolkit";
import {updateAppReturnUrlAction} from "../actions/appReturnUrlActions.ts";

export const initState: string|null = null;

const appReturnUrlReducer = createReducer(initState, builder => {
    // @ts-expect-error unused state
    builder.addCase(updateAppReturnUrlAction, (state, action) => {
        return action.payload as unknown as void;
    })
});

export default appReturnUrlReducer;
