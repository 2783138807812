import {createAction} from "@reduxjs/toolkit";
import {User} from "../../../domain/users/models/input/User.ts";

const BASE_NAME = 'CURRENT_USER/';

const UPDATE_CURRENT_USER_ACTION = BASE_NAME + 'UPDATE';
const CLEAR_CURRENT_USER_ACTION = BASE_NAME + 'CLEAR';

export const updateCurrentUserAction = createAction<User>(UPDATE_CURRENT_USER_ACTION);
export const clearCurrentUserAction = createAction(CLEAR_CURRENT_USER_ACTION);
