import {Settings} from "../../../domain/settings/models/input/Settings.ts";

const SETTINGS_COLOR_MAPPING: Record<string, string> = {
    'primaryColor': '--primary',
    'color1': '--color1',
    'color2': '--color2',
    'color3': '--color3',
    'color4': '--color4'
}

export class ThemeService {
    public static setSettingsSassColors = (settings: Settings) => {
        const rootStyle = document.documentElement.style;

        for (const prop of Object.keys(SETTINGS_COLOR_MAPPING)) {
            const value = settings[prop as keyof Settings];

            if (value && typeof value === 'string') {
                rootStyle.setProperty(SETTINGS_COLOR_MAPPING[prop], value)
            }
        }
    }

    public static getSassColors = () => {
        const rootStyle = document.documentElement.style;

        return {
            'primary': rootStyle.getPropertyValue('--primary'),
            'color1': rootStyle.getPropertyValue('--color1'),
            'color2': rootStyle.getPropertyValue('--color2'),
            'color3': rootStyle.getPropertyValue('--color3'),
            'color4': rootStyle.getPropertyValue('--color4'),
        }
    }

    public static setSassProperty = (property: string, value: string): void => {
        const rootStyle = document.documentElement.style;
        rootStyle.setProperty(property, value)
    }
}
