/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createTransform } from 'redux-persist';
import {TransformInbound} from "redux-persist/es/types";


export interface ReduxPersistExpireReducerConfig {
    persistedAtKey?: keyof TransformInbound<unknown, unknown>;
    expireSeconds?: number | null;
    expiredState?: any;
    autoExpire?: boolean;
}

const transformPersistence = (inboundState: TransformInbound<unknown, unknown>, config: ReduxPersistExpireReducerConfig) => {
    inboundState = inboundState || {};

    // If autoExpire is required i.e. user won't be setting the time
    // then on each update change the `persistedAt` to be current time
    // so that the rehydrater will pick it up based on this time if
    // the record is not updated for some time
    if (config.autoExpire && config.persistedAtKey && !inboundState[config.persistedAtKey]) {
        inboundState = Object.assign({}, inboundState, {
            [config.persistedAtKey]: (new Date()).getTime()
        });
    }

    return inboundState;
};

const transformRehydrate = (outboundState: TransformInbound<unknown, unknown>, config: ReduxPersistExpireReducerConfig) => {
    outboundState = outboundState || {};

    // Check for the possible expiry if state has the persisted date
    if (config.expireSeconds && config.persistedAtKey && outboundState[config.persistedAtKey]) {
        const startTime = (new Date(outboundState[config.persistedAtKey])).getTime();
        const endTime = (new Date()).getTime();

        const duration = endTime - startTime;
        const seconds = duration / 1000;

        // If the state is older than the set expiry time,
        // reset it to initial state
        if (seconds > config.expireSeconds) {
            return Object.assign({}, config.expiredState);
        }
    }

    return outboundState;
};

export const reduxPersistExpireReducer = (reducerKey: string, config: ReduxPersistExpireReducerConfig = {}) => {

    const defaults = {
        // Key to be used for the time relative to which store is to be expired
        persistedAtKey: '__persisted_at',
        // Seconds after which store will be expired
        expireSeconds: null,
        // State to be used for resetting e.g. provide initial reducer state
        expiredState: {},
        // Use it if you don't want to manually set the time and want the store to
        // be automatically expired if the record is not updated in the `expireSeconds` time
        autoExpire: false
    };

    config = Object.assign({}, defaults, config);

    return createTransform(
        // transform state on its way to being serialized and persisted.
        (inboundState: TransformInbound<unknown, unknown>) => transformPersistence(inboundState, config),
        // transform state being rehydrated
        (outboundState: TransformInbound<unknown, unknown>) => transformRehydrate(outboundState, config),
        // define which reducers this transform gets called for.
        {
            whitelist: [reducerKey]
        }
    );
}
