import {createContext} from "react";
import {User} from "../../../domain/users/models/input/User.ts";
import {UserAuth} from "../../../domain/users/models/input/UserAuth.ts";

export interface AuthContextInterface {
    loading: boolean,
    userAuth: UserAuth|undefined,

    updateUser: (user: User) => void,
    refetchUser: () => void,

    login: () => void,
    register: (email?:string, urlRedirect?: string) => void,
    logout: (redirectUri?: string) => void
}

export const AuthContext = createContext<AuthContextInterface>({
    loading: true,
    userAuth: undefined,

    updateUser: () => null,
    refetchUser: () => null,

    login: () => null,
    register: () => null,
    logout: () => null
});
